export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
  lightGreen: "#13ac7e",
  green: "#34d7a6",
  accent: "#EA4725",
  accentGradient: "#f36649",
  secondary: "#e5e4f5",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.accent}, ${
  COLORS.accentGradient
})`

export const BORDER_RADIUS = "10px"
