import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer style={{ fontSize: "14px" }}>
    © {new Date().getFullYear()}, {siteTitle} -
    <span style={{ marginLeft: ".3rem" }}>
      <a href="mailto:justin@warplanding.com" style={{ color: COLORS.accentGradient, fontWeight: 700, textDecoration: "none" }}>
        Contact Us
      </a>
    </span>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
